import 'babel-polyfill';
import $ from 'jquery';
import 'vendor/modernizr';
import { getClassByName } from 'core/controllers';

$(function() {
  let {
    controller,
    storeContext,
    locale
  } = $('body').data();

  let ControllerClass    = getClassByName(controller);
  let ControllerInstance = new ControllerClass({
    storeContext,
    locale
  });

  ControllerInstance.init();
});
